@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');

.footer-contents{
    background-color: rgb(46,39,39);
    font-family: 'Source Sans Pro', sans-serif;
}
.footer-main {
    height: 45px;
    display: flex;
    align-items: center;
    gap: 12px;
}
.footer-main a{
    text-decoration: none;
    color: rgb(207,158,35);
}
.footer-link{
    height: 45px;
    display: flex;
    align-items: center;
}
.footer-link a{
    text-decoration: none;
    color: rgb(207,158,35);
}
.footer-link a:hover{
    color: white;
}
.footer-alignment{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 23%;
}

@media only screen and (max-width: 600px){
    .footer-alignment {
        width: 100%;
        margin-top: 48px;
    }
}

@media only screen and (min-width: 600px){
    .footer-alignment {
        width: 100%;
        margin-top: 50px;
    }
    
}

@media only screen and (min-width: 992px){
    .footer-alignment {
        margin-top: 149px;
    }
}
@media only screen and (min-width: 1200px){
    .footer-alignment {
        width: 21%;
    }
}
@media only screen and (min-width: 1280px){
    .footer-alignment {
        width: 27%;
    }
}
@media only screen and (min-width: 1300px){
    .footer-alignment {
        width: 29%;
    }
}
@media only screen and (min-width: 1400px){
    .footer-alignment {
        width: 21%;
    }

}
@media only screen and (min-width: 1400px){
    /* .footer-alignment {
        width: 24%;
        margin-top: 300px;
    } */
    .footer-alignment {
        width: 24%;
        margin-top: 151px;
    }
}
@media only screen and (min-width: 1550px){
    .footer-alignment {
        width: 29%;
    }
}

@media only screen and (min-width: 1700px){
    .footer-alignment {
        width: 35%;
    }
    
}
@media only screen and (min-width: 1800px){
    .footer-alignment {
        width: 38%;
    }
}
@media only screen and (min-width: 1900px){
    .footer-alignment {
        width: 42%;
    }
}

