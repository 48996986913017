.trail-class {
    overflow: hidden;
}

.top-contents {

    font-family: 'Sniglet', sans-serif;
}

.top-contents input {
    height: 60px;
    font-size: 20px;
    width: 100%;
    border-right: none;
    border-left: none;
}
.top-contents input:focus{
    outline: none;
    box-shadow: none;
    border-top: none;
}

.top-contents img {
    height: 155px;
}

.top-contents p {
    color: rgb(24, 4, 255);
    font-size: 18px;
    font-weight: 500;
}


.top-contents h1 {
    color: rgb(71, 151, 170);
    font-size: 34px;
    font-weight: 700;
    text-align: left;
}
.top-contents h4{
    /* line-height: 56px; */
    margin-top: 24px;
}

.top-contents button {
    color: #fff;
    background-color: rgb(71, 151, 170);
    border: none;
    font-size: 20px;
    border-radius: 5px;
    font-weight: 700;
}

.btn {
    color: white;
}

.btn:hover {
    background-color: black;
    color: white;
}
#score{
    font-size: 36px;
}
#error-messages{
    color: red;
    text-align: left;
    font-size: 18px;
}




/* ......................2nd section......................... */
.pte_department{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Sniglet', sans-serif;
}
.selecting{
    font-family: 'Sniglet', sans-serif;
}

.pte_department img{
    height: 155px;
}
.pte_department h2{
    color: rgb(71, 151, 170);
    font-size: 34px;
    font-weight: 700;
    /* width: 600px; */
    line-height: 50px;
}
.pte_department select{
    width: 100%;
    height: 60px;
    font-size: 20px;
}
#check{
    color: red;
    width: 100%;
}
#plce-error{
    margin-top: 30px;
}
#select_button{
    color: #fff;
    background-color: rgb(71, 151, 170);
    border: none;
    height: 50px;
    width: 12%;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
}
#select_button:hover{
    background-color: black;
}

#choose{
    border-left: none;
    border-right: none;
    background-color: white;
    margin-top: 33px;
}
#choose:focus{
    outline: none;
    box-shadow: none;
    border-top: none;
}

/* ......................2nd section......................... */

/* ......................3rd section......................... */

/* Your CSS file */
#countrySelect{
    width: 100%;
    height: 60px;
    border-right: none;
    border-left: none;
    outline: none;
    box-shadow: none;
}
#countrySelect:focus{
    border-top: none;
}
  
#error-messageges{
    color: red;
    text-align: left;
    font-size: 18px;
}

.contact-num img{
    height: 155px;
}
#contact-button{
    color: #fff;
    background-color: rgb(71, 151, 170); 
    border: none;
    height: 34px;
    width: 12%;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
}
#contact-button:hover{
    background-color: black;
}
/* .react-tel-input .form-control {
   
    height: 60px !important;
    width: 100% !important;
   
} */
.contact-num h2{
    color: rgb(71, 151, 170);
    font-size: 34px;
    font-weight: 700;
}
.contact-num p{
    font-size: 20px;
    line-height: 47px;
}
.contact-num{
    font-family: 'Sniglet', sans-serif;
   
}
.inputTag{
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
}

#tag{
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-top: 20px;
}
#personel-num input{
    width: 300px;
    height: 60px;
    border-right: none;
    border-left: none;
    outline: none;
    box-shadow: none;
}
#personel-num input:focus{
    border-top: none;
}
/* .......................coutryCode...................... */

.country-dropdown{
    height: 100px;
    overflow-y: scroll;
    border: 1px solid black;
    width: 100%;
}
/* .fa{
    margin-left: -11px;
} */

/* #custom-dropdown input{
    width: 100%;
    height: 60px;
    border-right: none;
    border-left: none;
    outline: none;
    box-shadow: none;
}
#custom-dropdown input:focus{
    border-top: none;
} */


#custom-dropdown{
    width: 200px;
    position: relative;
    cursor: pointer;
    display: flex;
}

#drop{
    display: flex;
}
.fa {
    margin-top: 20px;
}

/* .......................coutryCode...................... */


/* ......................3rd section......................... */

/* ......................4th section......................... */
.pte_loc{
    font-family: 'Sniglet', sans-serif;
}
.pte_loc input{
    height: 60px;
    margin-top: 33px;
    border-left: none;
    border-right: none;
   
}
.pte_loc input:focus{
    outline: none;
    box-shadow: none;
    border-top: none;
}
.pte_loc h2{
    font-size: 34px;
    font-weight: 700;
    text-align: left;
}

.pte_loc img{
    height: 155px;
}
#add-ptn{
    color: #fff;
    background-color: rgb(71, 151, 170); 
    border: none;
    height: 43px;
    width: 22%;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
}
#add-ptn:hover{
    background-color: black;
}
#error{
    color: red;
    text-align: left;
    font-size: 18px;
}
#set-error{
    display: flex;
    justify-content: center;
    align-items: center;
}
#adjust-message{
    width: 60%;
}


/* ......................4th section......................... */
@media only screen and (max-width: 600px){
    /* footer#site-footer section#footer-bottom-section div {
        text-align: center;
        width: 80%;
        font-size: 14px;
    } */
    /* .fa {
        position: absolute;
        top: 21px;
        left: 109px;
    } */
    .pte_loc h2{
        font-size: 24px;
    }
    .contact-num p{
        font-size: 14px;
    }
    .contact-num h2{
        font-size: 24px;
    }
    .pte_department h2{
        font-size: 24px;
    }
    #score {
        font-size: 23px;
    }
    .top-contents h4{
        font-size: 15px;
    }
    .top-contents h1{
        font-size: 24px;
    }
    
    #select_button{
        width: 28%;
    }
    #contact-button{
        width: 28%;
        height: 50px;
    }
    #custom-dropdown {
        width: 100%;
    }
    #tag{
        flex-direction: column;
    }
    #personel-num input{
        width: 100%;
    }
    #add-ptn{
        width: 30%;
    }
}


@media only screen and (min-width: 600px){
    /* .fa {
        position: absolute;
        top: 21px;
        left: 172px;
    } */
}

@media only screen and (min-width: 768px) {
    #error-messageges {
       margin-left: 34px;
    }
    
}
@media only screen and (min-width: 992px){
    #error-messageges {
        margin-left: 54px;
     }
}
@media only screen and (min-width: 1200px) {

    .contact-num p{
        width: 548px;
        text-align: end;
    }
    .pte_department h2{
        width: 618px;
        text-align: right;
    }
    #error-messageges {
        margin-left: 20px;
     }
    #score {
        font-size: 36px;
        width: 584px;
    }
    .top-contents p {
        width: 642px;
    }
    .top-contents h1 {
        
        line-height: 87px;
    }
    .pte_department select{
        width: 100%;
    }
}
@media only screen and (min-width: 1400px){
    #error-messageges {
        margin-left: 66px;
     }
     .contact-num p {
        width: 592px;
        text-align: end;
    }
}