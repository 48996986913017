@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');


.pay-money {
    overflow: hidden;
}

.qr-scan {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.qr-scan img {
    height: 18rem;
}

.transfer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.banking {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#payment-title {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 37.76px;
}

.qr-scan button {
    width: 90px;
    height: 90px;
    border: none;
    background-color: black;
    color: white;
    font-size: 42px;
    border-radius: 46px;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

.qr-scan h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 27.68px;
    margin-top: 24px;

}

.transfer button {
    width: 90px;
    height: 90px;
    border: none;
    background-color: black;
    color: white;
    font-size: 42px;
    border-radius: 46px;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

.transfer h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 27.68px;
    text-align: center;
    /* line-height: 6rem; */
}

.transfer p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.banking button {
    width: 90px;
    height: 90px;
    border: none;
    background-color: black;
    color: white;
    font-size: 42px;
    border-radius: 46px;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

.banking h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 27.68px;
    text-align: center;
}



#click-here {
    color: rgb(40, 53, 147);
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

#razorpay {
    font-family: 'Source Sans Pro', sans-serif;

}

@media only screen and (max-width: 600px) {
    #payment-title {
        font-size: 32px;
    }

    .qr-scan {
        margin-top: 34px;
    }

    .transfer {
        margin-top: 48px;
    }

    .banking {
        margin-top: 48px;
    }
    .payment {
        margin-bottom: 4rem;
   }
}

@media only screen and (min-width: 600px) {
    .payment {
         margin-bottom: 4rem;
    }
    #payment-title {
        font-size: 32px;
    }

    .qr-scan {
        margin-top: 50px;
    }

    .transfer {
        margin-top: 50px;
    }

    .banking {
        margin-top: 50px;
    }

    .qr-scan img {
        height: 28rem;
    }

    .transfer h3 {
        margin-top: 20px;
    }

    .banking h3 {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .payment {
        /* height: 99rem; */
        margin-bottom: 6rem;
    }
    #payment-title {
        font-size: 32px;
    }

    .qr-scan img {
        height: 38rem;
        width: 70%;
    }
}

@media only screen and (min-width: 992px) {
    .payment {
        /* height: 99rem; */
        margin-bottom: 10rem;
    }
    #payment-title {
    
        margin-top: 10rem;
    }
    .qr-scan img {
        height: 18rem;
        padding: 36px 7px 0px 13px;
    }

    .transfer {
        margin-bottom: 42px;
    }

    .banking {
        margin-bottom: 196px;
    }

    .transfer p {
        text-align: center;
    }

    .banking h3 {
        margin-top: 15px;
    }

}

@media only screen and (min-width: 1200px) {
    .payment {
        /* height: 48rem; */
    }
    
    .transfer {
        margin-bottom: 176px;
    }

    .banking {
        margin-bottom: 274px;
    }

    .banking h3 {
        margin-top: 15px;
        width: 90%;
    }

    .qr-scan img {
        height: 23rem;
        padding: 36px 17px 0px 16px;
    }

    #payment-title {
        margin-top: 204px;
    }

}

@media only screen and (min-width: 1400px) {
    .transfer {
        margin-bottom: 208px;
    }

    .banking {
        margin-bottom: 307px;
    }

    .qr-scan img {
        padding: 7px 33px 23px 30px;
    }
}