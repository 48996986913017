@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');

.pay-opt{
    overflow: hidden;
}
.pay_title{
    text-align: center;
    
}
.pay_title h1{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 37.76px;
}
.transfer-card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.transfer-bank{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.transfer-card button{
    width: 90px;
    height: 90px;
    border: none;
    background-color: black;
    color: white;
    font-size: 42px;
    border-radius: 46px;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}
.transfer-bank button{
    width: 90px;
    height: 90px;
    border: none;
    background-color: black;
    color: white;
    font-size: 42px;
    border-radius: 46px;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}
.transfer-card h3{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 27.68px;
}
.transfer-bank h3{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 27.68px;
}
.transfer-bank p{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.transfer-card a{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    color: black;
}
.transfer-card a:hover{
    color: blue;
}

@media only screen and (max-width: 600px){
    .pay_title h1 {
        font-size: 26px;
        margin-top: 10rem;
    }
    .transfer-card {
        margin-top: 20px;
    }
    .transfer-bank{
        margin-top: 20px;
    }
    .pay_sec {
        margin-bottom: 6rem;
    }
    .transfer-card h3 {
        font-size: 23px;
        margin-top: 18px;
    }
    
    .transfer-bank h3{
        font-size: 23px;
        margin-top: 18px;
        text-align: center;
    }
    .transfer-bank p{
        font-size: 14px;
        text-align: center;
    }
   
}


@media only screen and (min-width: 600px){
    .pay_title h1 {
        font-size: 32px;
        margin-top: 8rem;
    }
    .transfer-card {
        margin-top: 24px;
    }
    .transfer-bank{
        margin-top: 24px;
    }
    .pay_sec {
        /* height: 86vh; */
        margin-bottom: 4rem;
    }
    .transfer-card h3 {
        margin-top: 16px;
    }
    .transfer-bank h3{
        margin-top: 16px;
    }
}



@media only screen and (min-width: 1200px){
    .pay_title h1{
        margin-top: 10rem;
        
    }
    .pay_sec {
    
        margin-bottom: 5rem;
    }
    
}
