.main-div {
    overflow: hidden;
}

/* -----------------whatsapp---------------- */
#pte-nav{
   
}
.whatsapp {
    /* display: flex;
  justify-content: flex-end; */
    /* position: sticky; */

}

.whatsapp button {
    height: 57px;
    width: 59px;
    background-color: rgb(37, 211, 102);
    border-radius: 30px;
    border: none;
    font-size: 20px;
    position: fixed;
    bottom: 10px;
    margin-left: 30px;
    transition: all 0.1s linear;
}

.whatsapp button:hover {
    background-color: rgb(52, 118, 76);
    transition: all 0.1s linear;
}

/* -----------------whatsapp---------------- */

/* ....................Recaptcha................ */

.recaptacha-set {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-top: 12px;
}

/* ....................Recaptcha................ */
.banner {
    /* background-image: linear-gradient(rgba(0, 0, 6, 0.18), rgba(0, 0, 6, 0.18)),
                  url(../assets/Images/PTEimg.png); */
    background: url('../assets/Images/PTEimg.png');
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover cover;
    margin-top: 70px;
    /* background-position: 90% center; */
}

.banner-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    width: 25%;
}


.bh-subtitle,
.bh-title,
.bh-text {
    line-height: 29px;
}

.bh-title {
    color: black;
}

.bh-title1 {
    color: black;
}

.bh-title2 {
    color: black;
}

.bh-subtitle {
    font-size: 16px;
    /* color: rgb(150,147,168); */
    color: black;
    letter-spacing: .1em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.bh-subtitle1 {
    font-size: 16px;
    color: rgb(150, 147, 168);
    /* color: white; */
    letter-spacing: .1em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.bh-subtitle2 {
    font-size: 16px;
    color: rgb(150, 147, 168);
    /* color: white; */
    letter-spacing: .1em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.bh-title {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.bh-title1 {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.bh-title2 {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.bh-text {
    font-size: 16px;
    /* color: rgb(71,151,170); */
    color: black;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;

}

.banner-btn {
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.banner-btn-wrapper {
    display: flex;
    gap: 1rem;
    /* width: 80%; */
}

/* .course-btn {
    border: 1px solid orange;
    background-color: orange;
    padding: 10px 30px;
    border-radius: 24px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
} */
.bh-subtitlez {
    color: grey;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
}

.bh-titlez {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.cf-textz {
    font-family: 'Poppins', sans-serif;
    color: grey;
    font-size: 14px;

}

.course-btn {
    border: 1px solid rgb(96,169,183);
    background-color: rgb(96,169,183);
    padding: 14px 37px;
    border-radius: 25px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}

.course-btn:hover {
    background-color: white;
    color: rgb(96,169,183);
}

.trial-btn {
    border: 1px solid black;
    background-color: transparent;
    padding: 14px 37px;
    border-radius: 25px;
    color: black;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}

.trial-btn:hover {
    background-color: black;
    color: white;
}

/* about */

.about-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    height: 500px;
    /* padding: 0px 10px 80px 10px; */
}

.about-text {
    width: 36%;
    margin-top: 15px;
}

.about-header h5 {
    color: #8f8f8f;
    font-size: 16px;
    letter-spacing: .2em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.about-header h1 {
    font-size: 32px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.about-para p {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}

.callback-form-section {
    width: 22%;
}

.callback-form-container {
    background-color: white;
    box-shadow: 0px 0px 50px 3px rgba(0, 0, 0, 0.05);

    z-index: 1;
    margin-top: -150px;
    padding: 40px 35px 40px 35px;
}

/* .callback-form span{
    font-size: 13px;
    color: #8f8f8f;
}

.callback-form h2{
    font-size: 24px;
} */

.cf-text {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.callback-form-wrapper form {
    display: flex;
    flex-direction: column;
}

.cf-label {
    padding-top: 1.5rem;
    padding-bottom: 5px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.error-message {
    color: red;
    font-family: 'Poppins', sans-serif;
}

.cf-label span {
    color: red;
    font-family: 'Poppins', sans-serif;
}

.cf-submit-btn {
    width: 25%;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(96,169,183);
    color: white;
    border: none;
    padding: 5px;
    margin-top: 1.5rem;
    font-family: 'Poppins', sans-serif;
}

.cf-submit-btn:hover {
    background-color: black;
    color: white;
}

/* course features */

.course-section {
    background-image: url('../assets/Images/bg_features-1.jpg');
    background-repeat: no-repeat;
    padding: 80px 0px 90px 0px;
}

.course-container {
    display: flex;
    justify-content: center;

}

.course-header {
    margin-bottom: 45px;
}

.course-features {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    gap: 4rem;
}

.course-feature-text {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
}

.course-features-left,
.course-features-middle {
    width: 28%;
}

.features {
    display: flex;
    align-items: center;
}

.feature-icon {
    background-color: black;
    color: white;
    border-radius: 50%;
    padding: 30px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 75px;
}

.feature-icon-title {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.feature-icon-text {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
}

.feature-text {
    flex: 1;
}

.course-features-right {
    display: flex;
    align-items: center;
}

.video-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    /* Hide any part of the image outside the container */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.video-container img {
    flex: 0 0 auto;
    /* Prevent the div from growing beyond its content */
    background-size: cover;
    /* Ensure the image covers the div */
    width: 100%;
    /* Ensure the div fills the container width */
    height: 100%;
}

.play-btn {
    position: absolute;
    background: none;
    border: none;
    color: white;
    font-size: 130px;
    margin-top: -25px;
}

/* Modal container */
.modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
}


/* Modal content */
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 80%;
    min-width: 300px;
    height: 80%;
}

/* Close button */
.close-button {
    position: absolute;
    top: 1rem;
    right: 120px;
    background: none;
    border: none;
    font-size: 32px;
    cursor: pointer;
    color: #fff;
    z-index: 1000;
}

/* .close-btn:hover {
    color: #ff0000;
} */

/* review */

.review-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 60px 50px;
}

.review-header {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.review-header h6 {
    font-size: 32px;
    color: #aebcb9;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.review-testimonial-slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: row; */
}

.slider-container {
    /* width: 65%; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* TestimonialSlider.css */

.testimonial-slider-wrapper {
    width: 85%;
    overflow: hidden;
    /* position: relative; */
    height: 20rem;
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

/* Testimonial Slider */
.testimonial-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    flex-direction: row;
    gap: 2rem;
}

.testimonial-container {
    flex: 0 0 100%;
    padding: 10px;
    text-align: center;
    transition: transform 0.5s ease-in-out;
    position: relative;
    margin: 0 auto;
    /* padding: 30px 40px; */
    padding: 30px 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.testimonial-header {
    display: flex;
    flex-direction: row;
    width: 65%;
    justify-content: space-evenly;
}

.testimonial img {
    width: 100px;
    /* Adjust the image size as needed */
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.testimonial-info {
    margin-bottom: 10px;
    margin-top: 0.5rem;
    text-align: left;
}

.testimonial h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.testimonial-info h6 {
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.rating {
    color: #f39c12;
    /* Star color */
    font-size: 1.2rem;
    margin-top: 5px;
    margin-bottom: 5px;
}

.star {
    margin-right: 2px;
}

.review {
    font-size: 1rem;
    margin: 0;
    /* margin-top: 2rem; */
    font-family: 'Poppins', sans-serif;
}

.slider-controls {
    margin-top: 20px;
}

.slider-control button {
    background-color: transparent;
    /* Button background color */
    color: #a7a7a7;
    border: none;
    padding: 5px 10px;
    font-size: 2rem;
    margin: 0 5px;
    cursor: pointer;
}

/* faq */

.faq-section {
    padding: 60px 50px;
    display: flex;
    justify-content: center;
}

.faq-container {
    width: 80%;
}

/* accordion */

.accordion {
    background-color: #fff;
    color: #000;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    text-align: left;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    transition: 0.4s;
    margin-top: 1.5rem;
    font-family: 'Poppins', sans-serif;
}

.accordion:after {
    content: '\02795';
    
    color: #000;
    font-weight: bold;
    float: left;
    margin-left: 5px;
    margin-right: 20px;
}
/* ................................... */

/* .active:after {
    content: "\2796";
    
} */

/* ................................... */

.active,
.accordion:hover {
    background-color: #eee;
}

.panel {
    padding: 15px 18px;
    border: 1px solid #dbdbdb;
    display: none;
    background-color: white;
    overflow: hidden;
}

.panel.show {
    display: block;
}

.panel-text {
    font-family: 'Poppins', sans-serif;
}

.join-steps {
    list-style-type: decimal;
}

.links {
    color: rgb(96,169,183);
    text-decoration: none;
}

.links:hover {
    color: black;
}




/* .............................moving............................. */

.slider {
    background: white;
    /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
    height: 148px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 960px;
}

.slider::before,
.slider::after {
    /* background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slide-track {
    animation: scroll 90s linear infinite;
    display: flex;
    width: calc(250px * 14);
    gap: 385px;
}

.slide:hover {

    scale: 1.27;
    transition: 1s;
}

.slide {
    height: 100px;
    width: 250px;
    transition: 1s;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 7));
    }
}



/* .testimonial-headers{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
  }
  .testimonial-headers h1{
    font-weight: 600;
  } */
.slider:hover .slide-track {
    animation-play-state: paused;
}


/* .............................moving............................. */
@media screen and (max-width: 1199px) {
    #setup {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .testimonial-slider {
        max-width: 100%;
        /* Make the slider full-width on small screens */
    }
    .banner{
        background-position: 92% center;
        background-image: linear-gradient(rgba(254, 254, 254, 0.684), rgba(254, 254, 254, 0.684)),
                  url(../assets/Images/PTEimg.png)
    }
    /* .banner-header {
        height: 0rem;
        width: 97%;
        padding-top: 24rem;
    } */

    /* #setup{
        display: none;
    } */
    /* .sub-slides{
        margin-bottom: 117px;
      } */
}

@media(max-width:600px) {
    
    .banner {
        
        background-image: linear-gradient(rgba(254, 254, 254, 0.684), rgba(254, 254, 254, 0.684)),
                  url(../assets/Images/PteImgs.png);
    
        height: 87vh;
    }

    /* .banner {
    background-position: 90% center;
} */
    #ports1{
        width: 90%;
    }
    #ports2{
        width: 90%;
    }
    #ports3{
        width: 90%;
    }
    #ports4{
        width: 90%;
    }

    .cf-label {
        white-space: nowrap;

    }

    .course-btn {

        padding: 9px 31px;

    }

    .trial-btn {

        padding: 9px 22px;

    }

    .video-container img {

        width: 81%;

    }

    .bh-subtitle {
        font-size: 14px;

        letter-spacing: .1em;
        font-weight: 400;

    }

    /* .course-section {
        
        height: 1244px;
    } */
    .banner-header {
        width: 100%;
        height: 44rem;
    }

    /* .banner-btn-wrapper{
        width: 93%;
    } */

    .about-section {
        flex-direction: column;
        align-items: center;
        height: 1345px;
    }

    .about-text {
        width: 84%;
    }

    .callback-form-section {
        width: 82%;
    }

    .callback-form-container {
        margin-top: 0;
        padding: 40px 51px 40px 57px;
    }

    .captcha {
        width: 100%;
    }

    .cf-submit-btn {
        width: 166px;
        border-radius: 30px;
        height: 47px;
    }

    .course-wrapper {
        padding: 20px 30px;
    }

    .course-features {
        display: flex;
        flex-direction: column;
    }

    .course-feature-text {
        flex-direction: column;
        width: 100%;
    }

    .course-features-left,
    .course-features-middle,
    .course-features-right {
        width: 100%;
    }

    .features {
        margin-bottom: 2rem;
    }

    .review-section {
        padding: 0px 0px;
    }

    .slider-container {
        width: 100%;
    }

    .testimonial-slider-wrapper {
        height: 35rem;
        padding: 10px 12px;
    }

    .testimonial-header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .testimonial-info {
        text-align: center;
    }

    .faq-container {
        width: 100%;
    }

    .accordion:after {
        display: none;
    }

    .slider {


        height: 499px;

        width: 492px;
    }

    .faq-section {
        padding: 60px 50px 122px 50px;

    }

    .testimonial-headers {

        height: 67px;
    }
}

@media(max-width:584px) {
    .slider {
        height: 499px;
        width: 465px;
    }
}

@media(max-width:560px) {
    /* .banner {
        background-position: 88% center;
    } */
    .slider {
        height: 499px;
        width: 441px;
    }
}

@media(max-width:510px) {
    /* .banner {
        background-position: 86% center;
    } */
    .slide-track {

        gap: 358px;
    }

    .slider {
        height: 499px;
        width: 393px;
    }
}

@media(max-width:501px) {
    .rc-anchor-logo-portrait {
        margin: 10px 0 0 -25px;
        width: 58px;

    }

    .rc-anchor-normal .rc-anchor-pt {
        margin: 2px 62px 0 0;
        padding-right: 2px;

        right: 0;

        width: 276px;
    }

}

@media(max-width:460px) {
    .banner {
        background-position: 57% center;
    }
    .slider {
        height: 499px;
        width: 343px;
    }

    .about-section {

        height: 1303px;
    }
}

@media(max-width:425px) {
    .slider {
        height: 499px;
        width: 310px;
    }

}

@media(max-width:405px) {
    /* .banner {
        background-position: 83% center;
    } */
    .video-container img {
        flex: 0 0 auto;
        background-size: cover;
        width: 87%;
        height: 100%;
    }

    .about-section {
        height: 1408px;
    }

}
@media(max-width:396px){
    .testimonial {
        height: 494px;
    }
}

@media(max-width:377px) {
    .review{
        font-size: 14px;
    }
    /* .banner {
        background-position: 81% center;
    } */
    .slider {
        height: 499px;
        width: 255px;
    }

    .features {
        flex-direction: column;
        gap: 10px;
    }

    .cf-label {
        white-space: nowrap;
        font-size: 14px;
    }

    .course-btn {
        padding: 7px 17px;
    }

    .trial-btn {
        padding: 7px 14px;
    }

    .banner-btn {
        gap: 1rem;
        margin-right: 46PX;
    }
}
@media(max-width:350px){
    .banner-header-wrapper{
        margin-left: 20px;
    }
    
    }
    /* @media(max-width:349px){
        .review {
            
            margin-top: 0rem;
            
        }
    } */
@media(max-width:331px) {
    .review{
        font-size: 13px;
    }
    .bh-text {
        font-size: 15px;
    }

    .banner-btn {
        gap: 1rem;
        margin-right: 22px;
    }
}



@media (min-width:600px) {
    
    .recaptacha-set {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* gap: 38px; */
        margin-top: 31px;
    }

    .video-container img {

        width: 83%;
        height: 70%;
    }

    .cf-submit-btn {
        width: 33%;
        height: 48px;

        border-radius: 32px;

        border: none;
        padding: 5px;
        margin-top: 1.5rem;

    }

    .banner-btn-wrapper {
        display: flex;
        gap: 1rem;
        /* width: 80%; */
        margin-right: 40px;
    }

    .bh-text {
        font-size: 14px;
        /* line-height: 7rem; */
        margin-bottom: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 27px;
    }

    .bh-subtitle {
        font-size: 19px;
        letter-spacing: .1em;
        font-weight: 400;
    }

    .bh-title {
        font-size: 36px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
    }

    .bh-text {
        font-size: 19px;
       
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
    }


    .banner-header {
        width: 100%;
        height: 54rem;
    }

    .about-section {
        flex-direction: column;
        align-items: center;
        height: 1386px;
    }

    .about-text {
        width: 100%;
        padding: 10px 55px;
    }

    .callback-form-section {
        width: 100%;
    }

    .callback-form-container {
        margin-top: 0;
        padding: 40px;
    }

    .course-wrapper {
        padding: 20px 30px;
    }

    .course-features {
        display: flex;
        flex-direction: column;
    }

    .course-feature-text {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .course-features-left,
    .course-features-middle,
    .course-features-right {
        width: 100%;
    }

    .features {
        margin-bottom: 2rem;
    }

    .video-container {
        height: 300px;
    }

    .slider-container {
        width: 100%;
    }

    .testimonial-header {
        width: 100%;
    }

    .faq-container {
        width: 100%;
    }

    .accordion:after {
        display: none;
    }


    .slider {
        height: 484px;
        width: 495px;
    }

    .testimonial-headers {
        text-align: center;
        height: 72px;
    }

    .faq-section {
        padding: 60px 50px 119px 50px;
        display: flex;
        justify-content: center;
    }

    .course-section {

        padding: 80px 0px 0px 0px;
    }
}

@media (min-width:768px) {
    .video-container img {
        width: 59%;
        height: 70%;
    }

    /* #sub-slides{
        display: none;
    } */
    /* .testimonial-container{
        flex: 0 0 44%;
    } */
    .cf-submit-btn {
        width: 24%;
        height: 48px;
        border-radius: 32px;

        padding: 5px;
        margin-top: 1.5rem;
    }

    .slider {
        height: 493px;
        width: 659px;
    }

    /* .banner-header {
        width: 97%;
        height: 55rem;
    } */
}

@media (min-width:769px) {
    .testimonial-container {
        flex: 0 0 60%;
    }
    #ports1 {
        width: 90%;
    }
    #ports2 {
        width: 90%;
    }
    #ports3 {
        width: 90%;
    }

    #ports4 {
        width: 90%;
    }

    #ports5 {
        width: 90%;
    }

    #ports6 {
        width: 90%;
    }
}

@media (min-width:800px) {
    .testimonial-container {
        flex: 0 0 64%;
    }

    #ports1 {
        width: 90%;
    }

    #ports2 {
        width: 90%;
    }

    #ports3 {
        width: 90%;
    }

    #ports4 {
        width: 90%;
    }

    #ports5 {
        width: 80%;
    }

    #ports6 {
        width: 90%;
    }

    #ports7 {
        width: 90%;
    }

}

@media (min-width:820px) {
    .testimonial-container {
        flex: 0 0 66%;
    }
}

@media (min-width:834px) {
    .testimonial-container {
        flex: 0 0 67%;
    }
}

@media (min-width:850px) {
    .testimonial-container {
        flex: 0 0 69%;
    }
}

@media (min-width:863px) {
    .testimonial-container {
        flex: 0 0 71%;
    }
}

@media (min-width:879px) {
    .testimonial-container {
        flex: 0 0 73%;
    }
}

@media (min-width:892px) {
    .testimonial-container {
        flex: 0 0 75%;
    }
}

@media (min-width:920px) {
    .testimonial-container {
        flex: 0 0 77%;
    }
}

@media (min-width:936px) {
    .testimonial-container {
        flex: 0 0 79%;
    }
}

@media (min-width:954px) {
    .testimonial-container {
        flex: 0 0 81%;
    }
}

@media (min-width:992px) {
    .callback-form-section {
        width: 90%;
    }

    .course-section {
        padding: 80px 0px 0px 20px;
    }

    .video-container img {
        width: 48%;
        height: 70%;
    }

    .cf-submit-btn {
        width: 29%;
        height: 48px;
        border-radius: 32px;
        padding: 5px;
        margin-top: 1.5rem;
    }


    .slider {
        height: 496px;
        width: 884px;
    }

    .about-section {
        flex-direction: column;
        align-items: center;
        height: 1243px;
    }

    .banner-header {
        width: 57%;
        height: 49rem;
    }

    .testimonial-container {
        flex: 0 0 85%;
    }
}


@media (min-width:1024px) {
    .callback-form-container {
        margin-top: 0;
        padding: 29px;
    }

    .video-container img {
        width: 67%;
        height: 70%;
    }

    /* .testimonial-container {
        flex: 0 0 85%;
    } */

    .cf-submit-btn {
        width: 85%;
        height: 48px;
        border-radius: 32px;
        /* padding: 5px; */
        margin-top: 1.5rem;
    }

    .course-features-left,
    .course-features-middle,
    .course-features-right {
        width: 92%;
    }

    .banner-header {
        width: 57%;
        height: 52rem;
    }

    .about-section {
        flex-direction: row;
        align-items: center;
        height: 678px;
    }

    .about-text {
        width: 57%;
        padding: 10px 30px;
    }

    .callback-form-section {
        width: 35%;
    }

    .callback-form-container {
        margin-top: -228px;
    }

    .course-features {
        flex-direction: column;
    }

    .course-feature-text {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    /* .course-features-left,
    .course-features-middle{
        width: 50%;
    } */

    .slider-container {
        width: 95%;
    }

    .testimonial-header {
        width: 80%;
    }

    .faq-container {
        width: 100%;
    }

    .accordion:after {
        display: block;
    }
}

@media (min-width:1054px) {
    .testimonial-container {
        flex: 0 0 86%;
    }
}

@media (min-width:1082px) {
    .testimonial-container {
        flex: 0 0 90%;
    }
}

@media (min-width:1118px) {
    .testimonial-container {
        flex: 0 0 92%;
    }
}

@media (min-width:1152px) {
    .testimonial-container {
        flex: 0 0 96%;
    }
}

@media screen and (min-width: 1199px) {
    #sub-slides {
        display: none;
    }
    .testimonial-container {
        flex: 0 0 100%;
    }
}

@media (min-width:1200px) {



    .video-container img {
        width: 98%;
        height: 93%;
        /* margin-top: -71px; */
    }

    .banner-btn-wrapper {
        margin-right: 193px;
    }

    .bh-text {
        font-size: 26px;
        height: 121px;
    }

    .bh-subtitle {
        font-size: 18px;
        letter-spacing: .1em;
        font-weight: 400;
    }

    .bh-subtitle,
    .bh-title,
    .bh-text {
        line-height: 40px;
    }

    .bh-title {
        font-size: 50px;

    }

    /* .testimonial-container {
        flex: 0 0 74%;
    } */

    .slider-container {
        width: 92%;
    }

    .cf-submit-btn {
        width: 48%;
        border-radius: 35px;
        /* height: 72px; */
        height: 50px;
        padding: 5px;
        margin-top: 1.5rem;
    }

    .testimonial-slider-wrapper {
        width: 100%;

        height: 20rem;

        padding: 10px 20px;
    }


    .faq-section {
        padding: 60px 107px 120px 134px;
        display: flex;
        justify-content: center;
    }

    .course-wrapper {
        padding: 20px 113px;
    }

    /* .banner-header {
        width: 60%;
        height: 53rem;
    } */
    .banner-header {
        width: 64%;
        height: 48rem;
    }

    /* .banner-header {
    width: 60%;
    height: 53rem;
} */
    .about-section {
        flex-direction: row;
        align-items: center;
        padding: 0px 5rem;
    }

    .video-container {
        /* height: 224px; */
        width: 415px;
    }

    .slider {
        height: 510px;
        width: 933px;
    }

    .slide-track {
        animation: scroll 90s linear infinite;
        display: flex;
        width: calc(250px * 14);
        gap: 385px;
    }

    .testimonial-headers {
        text-align: center;
        height: 96px;
    }
}

@media (min-width:1219px) {
    /* .testimonial-container {
        flex: 0 0 76%;
    } */
}

@media (min-width:1246px) {
    /* .testimonial-container {
        flex: 0 0 78%;
    } */
}

@media (min-width:1277px) {
    /* .testimonial-container {
        flex: 0 0 80%;
    } */
}

@media (min-width:1280px) {
    .banner-header {
        width: 64%;
        height: 48rem;
    }
    .about-text {
        width: 62%;
        padding: 10px 30px;
    }

    .course-section {
        padding: 80px 0px 0px 13px;
    }

    #ports1 {
        width: 93%;
    }

    #ports2 {
        width: 96%;
    }

    #ports3 {
        width: 93%;
    }

    #ports6 {
        width: 96%;
    }

    /* .testimonial-container {
        flex: 0 0 77%;
    } */

    .slider-container {
        width: 89%;
    }

    /* .cf-submit-btn {
        width: 30%;
       
        border-radius: 5px;
        
        
        padding: 5px;
        margin-top: 1.5rem;
        
    } */
    .about-section {
        flex-direction: row;
        align-items: center;
        padding: 0px 6rem;
    }

    .slider {
        height: 566px;
        width: 1027px;
    }

    .faq-section {
        padding: 60px 127px 158px 127px;
        display: flex;
        justify-content: center;
    }

    /* .banner-header {
        width: 48%;
        height: 53rem;
    } */
}

@media (min-width:1288px) {
    /* .testimonial-container {
        flex: 0 0 78%;
    } */
}

@media (min-width:1300px) {
    .faq-section {
        padding: 60px 127px 158px 142px;

    }

    .course-section {
        padding: 80px 0px 0px 29px;
    }

    .about-section {
        flex-direction: row;
        align-items: center;
        padding: 0px 7rem;
    }
}

@media (min-width:1318px) {
    /* .testimonial-container {
        flex: 0 0 80%;
    } */
}

@media (min-width:1350px) {
    /* .testimonial-container {
        flex: 0 0 83%;
    } */

    /* .faq-section {
        padding: 60px 159px;
        display: flex;
        justify-content: center;
    } */
    .about-section {

        padding: 0px 8rem;
    }

    .course-wrapper {
        padding: 20px 163px;
    }

    .slider {
        /* height: 445px; */
        width: 1070px;
    }
}

@media (min-width:1367px) {
    /* .testimonial-container {
        flex: 0 0 84%;
    } */
}
@media (min-width:1391px){
    /* .testimonial-container {
        flex: 0 0 85%;
    } */
}

@media (min-width:1400px) {

    .faq-section {
        padding: 60px 127px 158px 181px;
    }

    .course-section {
        padding: 80px 0px 0px 14px;
    }

    /* .testimonial-container {
        flex: 0 0 85%;
    } */

    /* .banner-header {
        width: 59%;
        height: 51rem;
    } */
    .banner-header {
        width: 62%;
        height: 51rem;
    }

    .slider {
        /* height: 445px; */
        width: 1072px;
    }

}

@media (min-width:1413px) {
    /* .testimonial-container {
        flex: 0 0 86%;
    } */
}
@media (min-width:1437px){
    /* .testimonial-container {
        flex: 0 0 88%;
    } */
}

@media (min-width:1440px) {
    .video-container img {
        width: 74%;
        margin-right: 93px;
    }
    /* .callback-form-container {
        margin-top: -228px;
        width: 24rem;
        margin-left: -15px;
    } */

    /* .video-container img {
        width: 98%;
        height: 100%;
    } */

    .testimonial-container {
        flex: 0 0 76%;
    }

    .faq-section {
        padding: 60px 18px 135px 18px;
        display: flex;
        justify-content: center;
    }

    .course-feature-text {
        gap: 1rem;
        width: 100%;
    }

    /* .banner-header {
        width: 48%;
        height: 52rem;
    } */
    .banner-header {
        width: 52%;
        height: 52rem;
    }

    .about-section {
        gap: 6rem;
    }

    .about-text {
        width: 63%;
    }

    .callback-form-section {
        width: 25%;
    }

    .course-features {
        flex-direction: row;
    }



    .course-features-left,
    .course-features-middle {
        width: 49%;
    }

    .course-feature-video {
        display: flex;
        align-items: center;
    }

    .video-container {
        height: 200px;
    }

    .slider-container {
        width: 60%;
    }

    .testimonial-header {
        width: 65%;
    }

    .faq-container {
        width: 80%;
    }

    .slider {
        /* height: 448px; */
        width: 1114px;
    }
}

@media (min-width:1473px) {
    .testimonial-container {
        flex: 0 0 79%;
    }
}

@media (min-width:1500px) {
    .testimonial-container {
        flex: 0 0 81%;
    }

    /* .banner-header {
        width: 39%;
    } */
    .slider {
        /* height: 448px; */
        width: 1156px;
    }
}

@media (min-width:1519px) {
    /* .testimonial-container {
        flex: 0 0 60%;
    } */
}

@media (min-width:1550px) {
    .slider {
        /* height: 448px; */
        width: 1202px;
    }

    .testimonial-container {
        flex: 0 0 83%;
    }
}

@media (min-width:1586px) {
    .testimonial-container {
        flex: 0 0 88%;
    }
}

@media (min-width:1600px) {
    

    .slider {
        /* height: 448px; */
        width: 1239px;
    }

    /* .cf-submit-btn {
        width: 35%;
        border-radius: 35px;
        
        height: 50px;
        padding: 5px;
        margin-top: 1.5rem;
    } */
}

@media (min-width:1625px){
    .testimonial-container {
        flex: 0 0 89%;
    }
}

@media (min-width:1634px) {
    /* .testimonial-container {
        flex: 0 0 66%;
    } */
}

@media (min-width:1650px) {
    .testimonial-container {
        flex: 0 0 91%;
    }

    .course-features {
        display: flex;
        flex-direction: row;
        max-width: 1307px;
        gap: 4rem;
    }

    .slider {
        /* height: 448px; */
        width: 1283px;
    }
}

@media (min-width:1680px) {
    .testimonial-container {
        flex: 0 0 93%;
    }
}

@media (min-width:1700px) {
    .about-section {
        padding: 0px 6rem;
    }

    .testimonial-container {
        flex: 0 0 95%;
    }

    /* .banner-header {
        height: 53rem;
        width: 42%;
    } */
    .banner-header {
        height: 52rem;
        width: 45%;
    }


    .about-text {
        padding: 10px 0px;
    }

    .callback-form-section {
        width: 22%;
    }

    .course-features {
        display: flex;
        flex-direction: row;
        max-width: 1400px;
        gap: 4rem;
    }

    .course-wrapper {
        padding: 20px 94px;
    }

    .slider {
        /* height: 448px; */
        width: 1325px;
    }
}

@media (min-width:1744px) {
    .testimonial-container {
        flex: 0 0 97%;
    }
}

@media (min-width:1750px) {
    .testimonial-container {
        flex: 0 0 99%;
    }

    .course-features {
        display: flex;
        flex-direction: row;
        max-width: 1425px;
        gap: 4rem;
    }
}

@media (min-width:1790px) {
    .testimonial-container {
        flex: 0 0 100%;
    }
}

@media (min-width:1800px) {
    #ports1 {
        width: 96%;
    }

    #ports2 {
        width: 96%;
    }

    #ports4 {
        width: 96%;
    }

    #ports6 {
        width: 96%;
    }

    /* .testimonial-container {
        flex: 0 0 62%;
    } */

    /* .cf-submit-btn {
        width: 27%;
        border-radius: 35px;
        height: 50px;
        padding: 5px;
        margin-top: 1.5rem;
    } */
    .banner-header {
        /* height: 48rem; */
        width: 45%;
    }

    .course-features {

        max-width: 1458px;
        gap: 4rem;
    }

    .slider {
        /* height: 448px;  */
        width: 1398px;
    }
}

@media (min-width:1824px) {
    /* .testimonial-container {
        flex: 0 0 76%;
    } */
}

@media (min-width:1850px) {
    /* .testimonial-container {
        flex: 0 0 77%;
    } */

    .course-features {
        max-width: 1493px;
        gap: 4rem;
    }

    .slider {
        /* height: 448px; */
        width: 1432px;
    }

}

@media (min-width:1887px) {
    /* .testimonial-container {
        flex: 0 0 79%;
    } */
}

@media (min-width:1900px) {
    /* .testimonial-container {
        flex: 0 0 80%;
    } */

    .slider {
        /* height: 448px; */
        width: 1482px;
    }
}

@media (min-width:1950px) {
    /* .testimonial-container {
        flex: 0 0 81%;
    } */
}