@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');

.payments{
    overflow: hidden;
}

.online-payments button{
    width: 90px;
    height: 90px;
    border: none;
    background-color: black;
    color: white;
    font-size: 42px;
    border-radius: 46px;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif; 
}
.direct-payment button{
    width: 90px;
    height: 90px;
    border: none;
    background-color: black;
    color: white;
    font-size: 42px;
    border-radius: 46px;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif; 
}
.online-payments{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.direct-payment{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.payment-heading{
    text-align: center;
}
.payment-heading h1{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 37.76px;
}
.online-payments a{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    color: black;
}
.online-payments a:hover{
    color: blue;
}
.online-payments h3{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 27.68px;
}
.transfer-bank h3{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 27.68px;
}
.direct-payment h3{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 27.68px;
}
.direct-payment p{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

@media only screen and (max-width: 600px){
    .payment-heading h1{
        font-size: 26px;
        margin-top: 10rem;
    }
    .online-payments{
        margin-top: 20px;
    }
    .direct-payment{
        margin-top: 20px;
    }
    .payment-contents{
        margin-bottom: 6rem;
    }
    .online-payments h3{
        font-size: 23px;
        margin-top: 18px;
    }
    .direct-payment h3{
        font-size: 23px;
        margin-top: 18px;
        text-align: center;
    }
    .direct-payment p{
        font-size: 14px;
        text-align: center;
    }
}

@media only screen and (min-width: 600px){
    .payment-heading h1{
        font-size: 32px;
        margin-top: 8rem;
    }
    .online-payments{
        margin-top: 24px;
    }
    .direct-payment{
        margin-top: 24px;
    }
    .online-payments h3{
        margin-top: 16px;
    }
    .direct-payment h3{
        margin-top: 16px;
    }
    .payment-contents{
        margin-bottom: 4rem;
    }
}

@media only screen and (min-width: 1200px){
    .payment-contents{
        margin-bottom: 5rem;
    }
    .payment-heading h1{
        margin-top: 10rem;
    }
}